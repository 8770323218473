export default (context, inject) => {
  const captureExt = (e) => {
    if($nuxt.$config.env.sentry) {
      $nuxt.$sentry.captureException(e);
      return;
    }
    console.log(e.code,e.message,e.name);
    console.error('ERRORE',e);
  }
  const captureMessage = (e) => {
    if($nuxt.$config.env.sentry) {
      $nuxt.$sentry.captureMessage(e);
      return;
    }
    console.log(e.code,e.message,e.name);
    console.error('ERRORE',e);
  }
  inject('captureException', captureExt)
  inject('captureMessage', captureMessage)
}
