import {Capacitor, registerPlugin} from '@capacitor/core';
import {Browser} from '@capacitor/browser';
import {App} from '@capacitor/app';
import {KeepAwake} from '@capacitor-community/keep-awake';
import {Keyboard} from "@capacitor/keyboard";


const AirLynPlugin = registerPlugin('AirLyn');


export default (context, inject) => {
  const getPlatorm = function () {
    if (Capacitor) {
      return Capacitor.getPlatform();
    }
    return null
  }

  const keepAwake = async () => {
    await KeepAwake.keepAwake();
  };

  const allowSleep = async () => {
    await KeepAwake.allowSleep();
  };


  const isSupported = async () => {
    const result = await KeepAwake.isSupported();
    return result.isSupported;
  };


  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  const backManager = {
    callback:function(){},
    fireEvent:function() {
      console.log('someone click Back',arguments)
      if(typeof $nuxt.$backEmitter.callback ==='function') {
        $nuxt.$backEmitter.callback();
      }
    }
  }

  inject('browser', Browser)
  inject('capacitor', Capacitor)
  inject('app', App)
  inject('keyboard', Keyboard)
  inject('getplatform', getPlatorm)
  inject('airlyn', AirLynPlugin)
  inject('base64ToBlob', b64toBlob)
  inject('backEmitter', backManager)
  App.addListener('appStateChange', ({isActive}) => {
    if (isSupported()) {
      if (isActive) {
        keepAwake()
      } else {
        allowSleep()
        /* logout on back
        try {
          if(context.store.state.user.isguest) {
            context.$auth.logout().then((r) => {
              console.log('need logout is guest...', r);
              context.store.state.user.isguest = false;
              context.store.state.user.currentuser = {}
            }).catch((r) => {
              console.error('cannot logout', r);
            })
          }
        } catch ($ex) {

        }
         */
      }
    }
  });

  App.getState().then((r) => {
    if (r.isActive) {
      if (isSupported()) {
        keepAwake()
      }
    }
  });
  App.addListener('backButton',backManager.fireEvent);
}
