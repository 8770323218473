






import Vue from 'vue'
import {mapGetters} from "vuex";

export default Vue.extend({
  computed: {
    ...mapGetters({
      getCurrentPage: 'getCurrentPage'
    }),
    currentPage() {
      return this.getCurrentPage
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    start () {
      this.loading = true
    },
    finish () {
      this.loading = false
    }
  }
})
