import * as mutations from './mutations'
import * as actions from './actions'

export const user = {
  namespaced: true,
  state () {
    return {
      currentuser: null,
      isguest:false,
      performed:[],
      skiptutorial:false,
      sessionCounter:0
    }
  },
  mutations,
  actions
}

