import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const defaultState = {
  videodevices: [],
  audiodevices: []
}

const inBrowser = typeof window !== 'undefined'
// if in browser, use pre-fetched state injected by SSR
const state = (inBrowser && window.__INITIAL_STATE__) ? window.__INITIAL_STATE__.page : defaultState

export default {
  state,
  actions,
  mutations,
  getters
}
