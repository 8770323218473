import Vue from 'vue'
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
//import VueConfetti from 'vue-confetti';


export default (context, inject) => {
  const doubleRequestAnimationFrame = (callback) => {
    requestAnimationFrame(() => {
      requestAnimationFrame(callback)
    })
  }
  const forceNextTick = (callback) => {
    if (callback && typeof callback === 'function') {
      doubleRequestAnimationFrame(callback)
    } else {
      return new Promise(resolve => {
        doubleRequestAnimationFrame(resolve)
      })
    }
  }
  inject('forceNextTick', forceNextTick)
}

Vue.use(Donut);
//Vue.use(VueConfetti);
