const allowedRoutes = [
  "welcome",
  "user-login",
  "user-signup"
]
export default function ({store, route}) {

  console.log('cambio rotta', store, route, $nuxt.$auth.loggedIn, $nuxt.getRouteBaseName(route));
  /**
   * Allowed logged out paths: login, welcome, signup
   */
  if ($nuxt.$auth.loggedIn === false) {
    if (allowedRoutes.indexOf($nuxt.getRouteBaseName(route)) === -1) {
      $nuxt.$router.replace("/welcome");
    }
  }
}
