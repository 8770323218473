import * as mutations from './mutations'
import * as actions from './actions'

export const exercises = {
  namespaced: true,
  state() {
    return {
      exercises: [],
      currentExerciseID: false,
      currentExercise: null,
      programs: [],
      currentProgramID: false,
      currentProgram: null,
      runningProgram: null
    }
  },
  mutations,
  actions
}
