export default {
  SET_CURRENTUSER: (state, payload) => {
    state.currentuser = payload
    let isloggedout = false;
    if(typeof state.currentuser.name === 'undefined') {
      isloggedout = true;
    } else if ($nuxt.$auth.loggedIn === false) {
      isloggedout = true;
    }
    if(isloggedout) {
      state.isguest = false;
      state.sessionCounter = 0;
      state.performed = [];
    }

  },
  SET_CURRENTUSERISGUEST: (state, payload) => {
    state.isguest = payload
    if (state.isguest) {
      state.sessionCounter = 0;
      state.skiptutorial = false

    }
  },
  SET_CURRENTUSERVERIFICATIONCODE: (state, payload) => {
    state.currentuser.verificationcode = payload
  },
  SET_PERFORMEDEXERCISES: (state, payload) => {
    state.performed = payload
  },
  SET_SKIPTUTORIAL: (state, payload) => {
    state.skiptutorial = payload
  },
  ADD_SESSIONCOUNTER: (state, payload) => {
    state.sessionCounter += payload
  }
}
