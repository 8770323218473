//import DeviceDetector from "device-detector-js";
import {Device} from '@capacitor/device';

export default (context, inject) => {
  const deviceInfo = {
    getInfo: async function () {
      //if (context.$capacitor) {
      //  console.log('siamo in piattaforma nativa, verifica device info');
        return new Promise((resolve, reject) => {
          Device.getInfo().then((dev) => {
            let parts = [];
            if (dev) {
              if (dev.name) {
                parts.push(dev.name)
              }
              if (dev.platform) {
                parts.push(dev.platform)
              }
              if (dev.osVersion) {
                parts.push(dev.osVersion)
              }
              if (dev.manufacturer) {
                parts.push(dev.manufacturer)
              }
            }
            resolve(parts.join(" "));
          });
        })
      //}
      /*
      return new Promise((resolve, reject) => {
        const deviceDetector = new DeviceDetector();
        const result = deviceDetector.parse(navigator.userAgent);
        let parts = [];
        if (result) {
          if (result.os && result.os.name) {
            parts.push(result.os.name)
          }
          if (result.os && result.os.version) {
            parts.push(result.os.version)
          }
          if (result.device && result.device.type) {
            parts.push(result.device.type)
          }
          if (result.device && result.device.brand) {
            parts.push(result.device.brand)
          }
          if (result.device && result.device.model) {
            parts.push(result.device.model)
          }
          resolve(parts.join(" "));
        } else {
          resolve(navigator.userAgent);
        }
      });
      */
    }
  }
  inject('deviceInfo', deviceInfo)
};
