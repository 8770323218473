export default {
  setVideoDevices: ({ commit }, payload) => {
    commit('SET_VIDEODEVICES', payload)
  },
  setAudioDevices: ({ commit }, payload) => {
    commit('SET_AUDIODEVICES', payload)
  },
  clearMediaDevices: ({commit},payload) => {
    commit('CLEAR_MEDIADEVICES', payload)
  },
  clearAudioDevices: ({commit},payload) => {
    commit('CLEAR_AUDIODEVICES', payload)
  },
  clearVideoDevices: ({commit},payload) => {
    commit('CLEAR_VIDEODEVICES', payload)
  },


}
