export default {
  setExercises: ({commit}, payload) => {
    commit('SET_EXERCISES', payload)
  },
  setCurrentExerciseIndex: ({commit}, payload) => {
    commit('SET_EXERCISEID', payload)
  },
  setCurrentExerciseById: ({commit}, payload) => {
    commit('SET_EXERCISE_BID', payload)
  },
  setPrograms: ({commit}, payload) => {
    commit('SET_PROGRAMS', payload)
  },
  setCurrentProgramIndex: ({commit}, payload) => {
    commit('SET_PROGRAMID', payload)
  },
  setCurrentProgramById: ({commit}, payload) => {
    commit('SET_PROGRAM_BID', payload)
  },
  setRunningProgram: ({commit}, payload) => {
    commit('SET_PROGRAM_RUNNING', payload)
  },
  updateExercise: ({commit}, payload) => {
    commit('UPD_EXERCISE', payload)
  }
}
