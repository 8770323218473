export default (app, inject) => {
  const signupManager = {
    context: null,
    //Signup POST /auth/signup
    signupUser(userinstance) {
      const params = new FormData();
      params.append('email', userinstance.username.toLowerCase());
      params.append('password', userinstance.password);
      //Meta info:
      let meta = {};
      Object.keys(userinstance).map((kp)=>{
        if(['username','password','name'].indexOf(kp)===-1) {
          meta[kp] = userinstance[kp]
        }
      });
      const jsonParams = {
        email: userinstance.username.toLowerCase(),
        password: userinstance.password,
        name: userinstance.name,
        meta: meta
      }
      const headers = {
        'Content-Type': 'application/json'
      }
      return signupManager.context.$axios.$post('/proxyapi/auth/signup', jsonParams, headers).then((r) => {
        console.log('[auth]got result', r);
        if (r && r.access_token) {
          userinstance.isverified = false;
          signupManager.context.$auth.setUserToken(r.access_token, r.access_token);
        }
        return r;
      });
    },
    updateProfile(userinstance) {
//Meta info:
      let meta = {};
      Object.keys(userinstance).map((kp)=>{
        if(['username','password','name'].indexOf(kp)===-1) {
          meta[kp] = userinstance[kp]
        }
      });
      let jsonParams = {
        password: userinstance.password,
        name: userinstance.name,
        meta: meta
      }
      if(typeof userinstance.password !=='undefined') {
        jsonParams['password'] = userinstance.password
      }
      const headers = {
        'Content-Type': 'application/json'
      }
      return signupManager.context.$axios.$patch('/proxyapi/user', jsonParams, headers).then((r) => {
        console.log('[userProfile]got result', r);
        return r;
      });
    },

    //Submit verification code (signup process) POST /auth/verify
    verifyCode(code) {
      const jsonParams = {
        verificationCode: code
      }
      const headers = {
        Authorization: signupManager.context.$auth.strategy.token.get(),
        'Content-Type': 'application/json'
      }
      return signupManager.context.$axios.$post('/proxyapi/auth/verify', jsonParams, headers);
    },
    //Get new verification code (signup process) POST /auth/new_verification_code
    requestNewVerificationCode(code) {
      const params = new FormData();
      params.append('verificationCode', code);
      const headers = {
        Authorization: signupManager.context.$auth.strategy.token.get()
      }
      return signupManager.context.$axios.$post('/proxyapi/auth/new_verification_code', params, headers);
    }
  }
  signupManager.context = app;

  console.log('ready for signup manager', signupManager.context);

  inject('signupManager', signupManager)
}
