//const humanizeDuration = require("humanize-duration");

export default (context, inject) => {

  const growingAnimation = {
    "scale": 2.5
  };
  const breathAnimation = {
    "scale": {value: '*=1'},
    "direction": 'alternate',
    "duration": 1000,
    "loop": true
  };
  const pulseAnimation = {
    "scale": [1, 2],
    "direction": 'alternate',
    "duration": 3000,
    "loop": true
  };
  const degrowthAnimation = {
    "scale": 1
  };
  const timeString = function (seconds) {
    return new Date(seconds * 1000).toISOString().substr(14, 5)
  }
  const humanizeTime = function (seconds, options) {
    const suffix ="sec";
    let _secondRound = Math.round(seconds/10)*10;
    if(_secondRound<60) {
       return _secondRound+" "+suffix;
    }
    const _minutes = Math.floor(_secondRound/60);
    const _resSeconds = _secondRound - (_minutes*60);
    if(_resSeconds === 0) {
      return _minutes+" min";
    }
    return _minutes+":"+_resSeconds+" "+suffix;
    const ms = seconds * 1000;
  }
  inject('animationHelper', {
    "growing": growingAnimation,
    "breathing": breathAnimation,
    "hold": breathAnimation, /*same as breathing*/
    "degrowing": degrowthAnimation,
    "pulse": pulseAnimation,
  });
  inject('timeString', timeString)
  inject('humanizeTime', humanizeTime)
}
