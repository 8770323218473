import {Store} from 'vuex'

import pagerModule from './modules/pager'
import exercisesModule from './modules/exercises'
import mediadevicesModule from './modules/mediadevices'
import userModule from './modules/user'

const createStore = () => {
  return new Store({
    namespaced: true,
    modules: {
      pager: pagerModule,
      exercises: exercisesModule,
      mediadevices: mediadevicesModule,
      user: userModule
    }
  })
}

export default createStore

export const getters = {
  isAuthenticated(state) {
    return state.auth.loggedIn
  },
  loggedInUser(state) {
    return state.auth.user
  }
}
