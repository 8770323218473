import * as mutations from './mutations'
import * as actions from './actions'

export const mediadevices = {
  namespaced: true,
  state () {
    return {
      videodevices: [],
      audiodevices: []
    }
  },
  mutations,
  actions
}
