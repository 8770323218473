export default function ({$auth}) {
  $auth.onRedirect((to, from) => {
    console.log(to, from);
    if (from.indexOf('signup')>-1 || from.indexOf('login')>-1) {
      console.log('siamo in signup/login, lascia il mondo', from);
      return from;
    }
    return to;
  })
}
