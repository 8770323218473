import * as mutations from './mutations'
import * as actions from './actions'

export const pager = {
  namespaced: true,
  state () {
    return {
      currentpage: null,
      currentpagerheight: null
    }
  },
  mutations,
  actions
}

