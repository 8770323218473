export default {
  SET_EXERCISES: (state, payload) => {
    console.log('mi chiedi di fare il set', payload, state)
    state.exercises = payload
  },
  SET_EXERCISEID: (state, payload) => {
    if (state.exercises && state.exercises.length > payload) {
      state.currentExerciseID = payload;
      state.currentExercise = state.exercises[payload];
    }
  },
  UPD_EXERCISE: (state, payload) => {
    if (state.exercises && state.exercises.length > 0) {
      const _id = payload.id || payload.__id;
      const indOf = state.exercises.findIndex((v) => v._id == _id || v.id == _id);
      if (indOf > -1) {
        state.exercises[indOf] = payload; //Aggiorna il dato
        state.currentExerciseID = indOf;
        state.currentExercise = state.exercises[indOf];
      }
    }
  },
  SET_EXERCISE_BID: (state, payload) => {
    if (state.exercises && state.exercises.length > 0) {
      const indOf = state.exercises.findIndex((v) => v._id == payload || v.id == payload);
      if (indOf > -1) {
        state.currentExerciseID = indOf;
        state.currentExercise = state.exercises[indOf];
      }
    }
  },
  SET_PROGRAMS: (state, payload) => {
    state.programs = payload
  },
  SET_PROGRAMID: (state, payload) => {

    if (state.programs && state.programs.length > payload) {
      state.currentProgramID = payload;
      state.currentProgram = state.programs[payload];
    }
  },
  SET_PROGRAM_BID: (state, payload) => {
    if (state.programs && state.programs.length > 0) {
      const indOf = state.programs.findIndex((v) => v._id == payload || v.id == payload);
      if (indOf > -1) {
        state.currentProgramID = indOf;
        state.currentProgram = state.programs[indOf];
      }
    }
  },
  SET_PROGRAM_RUNNING: (state, payload) => {
    state.runningProgram = payload
  },
}
