export default {
  setCurrentUser: ({commit}, payload) => {
    commit('SET_CURRENTUSER', payload)
  },
  setIsGuest: ({commit}, payload) => {
    commit('SET_CURRENTUSERISGUEST', payload)
  },
  setCurrentUserVerificationCode: ({commit}, payload) => {
    commit('SET_CURRENTUSERVERIFICATIONCODE', payload)
  },
  setPerformed: ({commit}, payload) => {
    commit('SET_PERFORMEDEXERCISES', payload)
  },
  setSkipTutorial: ({commit},payload) => {
    commit('SET_SKIPTUTORIAL',payload)
  },
  addDoneExercise: ({commit},payload) => {
    commit('ADD_SESSIONCOUNTER',payload)
  }

}
