const errorExerciseImage = require('~/assets/images/errorlogo.png');

export default (context, inject) => {

  const _sampleProgram = {
    "_id": "62e23c5d284d4819bc361509",
    "title": "Fuji",
    "order": 2,
    "exercises": [
      "62e3bae3c69d1c94fa07527e",
      "62d9575e7efd8ce98c4749a1",
      "62e3bae3c69d1c94fa07527e"
    ],
    "createdAt": "2022-07-22T15:46:58.602Z",
    "updatedAt": "2022-08-01T13:25:35.119Z",
    "__v": 0,
    "about": "<p>This is an about section with an <strong>image</strong>.</p><figure class=\"image\"><img src=\"https://storage.gra.cloud.ovh.net/v1/AUTH_85e67ae52bb64033a9e08434aa44899a/voicemed2-assets-bucket-dev/assets/image-1659015616921-Group-305.jpg\"></figure><p>Other text here.</p>",
    "thumbnail": "https://storage.gra.cloud.ovh.net/v1/AUTH_85e67ae52bb64033a9e08434aa44899a/voicemed2-assets-bucket-dev/assets/thumbnail-1659015596388-mountains-painting.jpg"
  };
  const _sampleExercise = {
    "_id": "62e3bae3c69d1c94fa07527e",
    "title": "Another Breathing",
    "thumbnail": null,
    "type": "recording",
    "subtype": null,
    "order": 0,
    "about": "<p>Write something here.</p>",
    "cycles": 1,
    "totalDuration": 20,
    "recordingPhases": [
      {
        "mainText": "In",
        "secondaryText": "",
        "duration": 10,
        "animationType": "growing",
        "image": null
      },
      {
        "mainText": "Out",
        "secondaryText": "",
        "duration": 10,
        "animationType": "degrowing",
        "image": null
      }
    ],
    "audio": null,
    "createdAt": "2022-07-29T10:48:03.589Z",
    "updatedAt": "2022-07-29T10:48:03.589Z",
    "__v": 0
  };
  const resManager = {
    kindRECORDING: 'recording',
    kindAUDIO: 'audio',
    kindVIDEO: 'video',
    kindHOLD: 'hold',
    app: null,
    demo: false, /*enable this to create dummy contents*/
    parseJsonDate: function (date) {
      if (date && date.indexOf('T') > -1) {
        const d = Date.parse(date);
        if (!isNaN(d)) {
          return d;
        }
      }
      return date;
    },
    formatDate: function (date) {
      if (!isNaN(date)) {
        const d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return date;
    },
    parseExercise: function (item) {
      let exercise = {
        "createdAt": item.createdAt,
        "updatedAt": item.updatedAt,
        "order": item.order,
        "title": item.title,
        "about": item.about || "",
        "intro": item.intro || "",
        "tutorialVideo": item.tutorialVideo || false,
        "tutorialVideoThumbnail": item.tutorialVideoThumbnail || false,
        "type": item.type,
        "subtype": item.subtype,
        "id": item._id,
        "thumb": item.thumbnail,
        "duration": item.totalDuration,
        "durationMs": item.totalDuration * 1000,
        "exercises": [],
        "completed": false,
        "cycles": item.cycles || 1,
        "audio": item.audio || null,
        "video": item.video || null,
        "videoThumbnail": item.videoThumbnail || false,
        "__v": item.__v || 0
      };
      if (exercise.createdAt) {
        exercise.createdAtDate = resManager.parseJsonDate(exercise.createdAt);
      }
      if (exercise.updatedAt) {
        exercise.updatedAtDate = resManager.parseJsonDate(exercise.updatedAt);
      }

      if (item.recordingPhases) {
        //Ripeti le recordin phases per ciascun ciclo previsto dall'app stessa.
        for (var i = 0; i < exercise.cycles; i++) {
          item.recordingPhases.map((exercisesListItem, indExL) => {
            let iExe = {
              "animationType": exercisesListItem.animationType,
              "duration": exercisesListItem.duration,
              "durationMs": exercisesListItem.duration * 1000,
              "title": exercisesListItem.mainText,
              "description": exercisesListItem.secondaryText,
              "thumb": exercisesListItem.image,
              "iteration": (i + 1),
              "pause": false,
              "hold": false
            }
            if (typeof exercisesListItem.hold !== 'undefined' && exercisesListItem.hold !== null) {
              iExe.hold = exercisesListItem.hold;
            }

            if (iExe.animationType === 'growing') {
              iExe.animation = resManager.app.$animationHelper.growing;
            } else if (iExe.animationType === 'breathe') {
              iExe.animation = resManager.app.$animationHelper.breathing;
            } else if (iExe.animationType === 'degrowth') {
              iExe.animation = resManager.app.$animationHelper.degrowing;
            } else if (iExe.animationType === 'degrowing') {
              iExe.animation = resManager.app.$animationHelper.degrowing;
            } else if (iExe.animationType === 'breathing') {
              iExe.animation = resManager.app.$animationHelper.breathing;
            } else if (iExe.animationType === 'hold') {
              iExe.animation = resManager.app.$animationHelper.hold;
            } else if (iExe.animationType === 'pulse') {
              iExe.animation = resManager.app.$animationHelper.pulse;
            }
            if (iExe.hold) {
              iExe.duration = -1;
              iExe.durationMs = -1000;
            }
            exercise.exercises.push(iExe);
            if (iExe.hold) {
              let pExe = {
                "animationType": 'hold',
                "duration": exercisesListItem.duration,
                "durationMs": exercisesListItem.duration * 1000,
                "title": 'pause',
                "description": null,
                animation: resManager.app.$animationHelper.hold,
                "thumb": null,
                "iteration": (i + 1),
                "pause": true,
                "hold": false
              }
              if (indExL === item.recordingPhases.length - 1 && i < exercise.cycles - 1) {
                exercise.exercises.push(pExe);
              } else if (indExL < (item.recordingPhases.length - 1)) {
                exercise.exercises.push(pExe);
              }
            }
          });
        }
      }
      return exercise;
    },
    parseProgram: function (item) {
      let program = {
        "id": item._id,
        "title": item.title,
        "isOpen": item.isOpen,
        "order": item.order,
        "exercises": [],
        "exercises_ids": [],
        "createdAt": item.createdAt,
        "updatedAt": item.updatedAt,
        "__v": item.__v || 0,
        "about": item.about || "",
        "thumb": item.thumbnail,
      }
      if (program.createdAt) {
        program.createdAtDate = resManager.parseJsonDate(program.createdAt);
      }
      if (program.updatedAt) {
        program.updatedAtDate = resManager.parseJsonDate(program.updatedAt);
      }
      if (item.exercises) {
        item.exercises.map((exeItem, exIndex) => {
          if (typeof exeItem === 'string') {
            if (program.exercises_ids.indexOf(exeItem) < 0) {
              program.exercises_ids.push(exeItem);
            }
          } else if (typeof exeItem === 'object') {
            const tmpE = resManager.parseExercise(exeItem);
            tmpE.program_index = exIndex;
            tmpE.program_id = item._id;

            if (program.exercises_ids.filter((v) => v.id === tmpE.id).length === 0) {
              program.exercises_ids.push(tmpE.id)
              program.exercises.push(tmpE);
            }
          }
        });
      }
      return program;
    },
    retrieveExercises: function () {
      const headers = {
        Authorization: resManager.app.$auth.strategy.token.get()
      }
      return new Promise((resolve, reject) => {
        resManager.app.$axios.$get('/proxyapi/user/breathing_exercises', headers).then((r) => {
          let exercises = [];
          if (r && Array.isArray(r)) {
            r.map((item) => {
              const tmpE = resManager.parseExercise(item);
              exercises.push(tmpE);
            });
          }
          if (resManager.demo) {
            for (var i = 0; i < 10; i++) {
              let tmpE = resManager.parseExercise(_sampleExercise);
              tmpE.id = tmpE.id + Math.floor(Math.random() * 11);
              exercises.push(tmpE);
            }
          }
          resolve(exercises);
        }).catch((e) => {
          let exercises = [];
          console.error('retrieve exercises error', e);
          resManager.app.$captureException(e);
          if (resManager.demo) {
            for (var i = 0; i < 10; i++) {
              let tmpE = resManager.parseExercise(_sampleExercise);
              tmpE.id = tmpE.id + Math.floor(Math.random() * 11);
              exercises.push(tmpE);
            }
          }
          resolve(exercises);
        });
      });
    },
    retrievePrograms: function () {
      const headers = {
        Authorization: resManager.app.$auth.strategy.token.get()
      }
      return new Promise((resolve, reject) => {
        resManager.app.$axios.$get('/proxyapi/user/programs', headers).then((r) => {
          let programs = [];
          if (r && Array.isArray(r)) {
            r.map((item) => {
              const tmpE = resManager.parseProgram(item);
              programs.push(tmpE);
            });
          }

          if (resManager.demo) {
            for (var i = 0; i < 10; i++) {
              let tmpE = resManager.parseProgram(_sampleProgram);
              tmpE.id = tmpE.id + Math.floor(Math.random() * 11);
              programs.push(tmpE);
            }
          }
          resolve(programs);

        }).catch((e) => {
          let programs = [];
          console.error('retrieve programs error', e);
          resManager.app.$captureException(e);
          if (resManager.demo) {
            for (var i = 0; i < 10; i++) {
              let tmpE = resManager.parseProgram(_sampleProgram);
              tmpE.id = tmpE.id + Math.floor(Math.random() * 11);
              programs.push(tmpE);
            }
          }
          resolve(programs);
        });
      });

    },
    fillProgram: function (item) {
      const _id = item.id || item._id;
      const headers = {
        Authorization: resManager.app.$auth.strategy.token.get()
      }
      return new Promise((resolve, reject) => {
        resManager.app.$axios.$get('/proxyapi/user/programs/' + _id, headers).then((r) => {
          if (r) {
            const program = resManager.parseProgram(r);
            Object.keys(program).map((pk) => {
              if (pk !== 'exercises' && pk !== 'exercises_ids') {
                item[pk] = program[pk];
              }
            });
            item.exercises.splice(0, item.exercises.length);
            if (program.exercises) {
              program.exercises.map((exe) => {
                item.exercises.push(exe)
                if (item.exercises_ids.indexOf(exe.id) < 0) {
                  item.exercises_ids.push(exe.id);
                }
              })
            }

          }
          return item;
        }).then((item) => {
          console.log('retrieved new program filled', item);
          resManager.app.$axios.$get('/proxyapi/user/programs/' + _id + "/status", headers).then((r) => {
            if (r) {
              if (r.exercises) {
                r.exercises.map((exe) => {
                  let idx = item.exercises.findIndex((ex) => ex.id == exe._id);
                  if (idx) {
                    item.exercises[idx]['completed'] = exe.completed;
                  }
                });
              }
            }
            resolve(item)
          }).catch((e) => {
            console.error('retrieve program[' + _id + '].status error', e);
            resManager.app.$captureException(e);

            resolve(item);
          })
        }).catch((e) => {
          console.error('retrieve program[' + _id + '] error', e);
          resManager.app.$captureException(e);
          if (resManager.demo) {
            item.exercises_ids.map((i, idx) => {
              item.exercises.push({
                'id': i,
                'title': 'exe ' + i,
                'duration': 0,
                'thumb': errorExerciseImage,
                'completed': false
              });
            });
          }
          resolve(item);
        });
      });
    },
    completeExercise: function (item, uploadprogress, audio, additionalData = {}) {
      const _id = item.id || item._id;
      let method = item.type === resManager.kindRECORDING ? 'POST' : 'PUT';
      let suffix = item.type === resManager.kindRECORDING ? 'recordings' : 'complete';
      let completeUrl = '/proxyapi/user/breathing_exercises/' + _id + "/" + suffix;
      if (typeof item.program_id !== 'undefined') {
        method = "POST";
        suffix = "complete";
        completeUrl = '/proxyapi/user/programs/' + item.program_id + '/' + item.program_index + '/' + _id + "/" + suffix;
      }

      const params = new FormData();
      if (item.type === resManager.kindRECORDING && typeof audio !== 'undefined' && audio !== null) {
        params.append('audio', audio, audio.name);
      }
      params.append('device_code', resManager.app.$deviceInfo.getInfo())
      if (typeof additionalData !== 'undefined' && additionalData !== null) {
        Object.keys(additionalData).map((v) => {
          params.append(v, additionalData[v])
        })
      }
      item.exerciseInfo = additionalData;
      const config = {
        method: method,
        url: completeUrl,
        data: params,
        Authorization: resManager.app.$auth.strategy.token.get(),
        onUploadProgress: (progress) => {
          if (typeof uploadprogress === 'function') {
            uploadprogress(progress);
          }
        }
      }
      return new Promise((resolve, reject) => {
        resManager.app.$axios.$request(config).then((r) => {
          if (r) {
            if (r.message) {
              item.status = r.message;
            }
            if (r.exercisesDone) {
              item.completed = true;
            }
            if (r.breathingScore) {
              item.breathingScore = r.breathingScore;
            }
            if (r.breathingTime) {
              item.breathingTime = r.breathingTime;
            }
            if (r.heldTime) {
              item.heldTime = r.heldTime;
            }
            if (item.type === resManager.kindRECORDING) {
              if (r.message && r.message === 'File uploaded successfully') {
                item.completed = true;
              } else {
                item.completed = false;
              }
            }
            if (item.completed) {
              $nuxt.$root.$emit('completedExercise', item)
            }
          }
          resolve({
            exercise: item,
            response: r,
            exerciseInfo: additionalData
          });
        }).catch((e) => {
          console.error("Errore invio api [breathing_exercises]:", e);
          resManager.app.$captureException(e);
          reject(e)
        })
      });
    },
    programStatus: function (item) {
      const _id = item.id || item._id;
      const headers = {
        Authorization: resManager.app.$auth.strategy.token.get()
      }
      return new Promise((resolve, reject) => {
        resManager.app.$axios.$get('/proxyapi/user/programs/' + _id + "/status", headers).then((r) => {
          if (r) {
            if (r.exercises) {
              r.exercises.map((exe) => {
                let idx = item.exercises.findIndex((ex) => ex.id == exe._id);
                if (idx) {
                  item.exercises[idx]['completed'] = exe.completed;
                  if (exe.breathingScore) {
                    item.exercises[idx]['breathingScore'] = exe.breathingScore;
                  }
                  if (r.breathingTime) {
                    item.exercises[idx]['breathingTime'] = exe.breathingTime;
                  }
                  if (r.heldTime) {
                    item.exercises[idx]['heldTime'] = exe.heldTime;
                  }
                }
              });
            }
          }
          resolve(item)
        }).catch((e) => {
          console.error('retrieve program[' + _id + '].status error', e);
          resManager.app.$captureException(e);
          resolve(item);
        })
      });
    },
    retrievePerformedAudio: function (item) {
      const _id = item.id || item._id;
      item.isloading = true;
      const headers = {
        Authorization: resManager.app.$auth.strategy.token.get(),
        responseType: 'blob'
      }
      return new Promise((resolve, reject) => {
        resManager.app.$axios.$get('/proxyapi/user/performed_exercises/' + _id + "/recording", headers).then((r) => {
          if (r) {
            console.log('got blob data', r);
            if ($nuxt.$capacitor.getPlatform() === 'ios') {
              const reader = new FileReader();
              reader.readAsDataURL(r);
              reader.onloadend = () => {
                resolve(reader.result)
              }
            } else {
              const url = window.URL.createObjectURL(new Blob([r]));
              resolve(url);
            }
            return;
          }
          resolve(false);
        }).catch((e) => {
          console.error("Errore retrieve", e);
          resolve(false);
        })
      })
    }
  }
  resManager.app = context;

  inject('exerciseManager', resManager
  )

}
;
