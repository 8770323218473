export default ({ $axios, app }) => {

    $axios.onRequest((config) => {
      console.log('request url: ', config.url);
      const urlKeys = ['/proxyapi/', '/proxystorage/', '/proxygooglemaps/']
      const founds = urlKeys.filter((url) => {
        if (config.url.substring(0, url.length) === url) {
          return true
        }
        return false
      })
      if (founds.length > 0) {
        console.log(founds, config.url);
        const newUrl = $nuxt.$config.env.urls[founds[0]] + config.url.substring(founds[0].length)
        console.log('new URL', newUrl)
        config.url = newUrl;
      }
      return config
    })
  $axios.onError(error => {
    let errorJson = {
      statusCode:error.response.status,
      message:error.response.data.message||"",
      error:error.response.data.error||""
    }
    return Promise.reject(errorJson);
  })
  $axios.onResponse(response => {
    //console.log('got Axios Response', response);
    return Promise.resolve(response);
  })
}
