export default {
  SET_VIDEODEVICES: (state, payload) => {
    state.videodevices = payload
  },
  SET_AUDIODEVICES: (state, payload) => {
    state.audiodevices = payload
  },
  CLEAR_MEDIADEVICES: (state, payload) => {
    state.audiodevices = [];
    state.videodevices = [];
  },
  CLEAR_AUDIODEVICES: (state, payload) => {
    state.audiodevices = [];
  },
  CLEAR_VIDEODEVICES: (state, payload) => {
    state.videodevices = [];
  }
}
